<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('project.edit','详情'):$l('project.add','详情')"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-tabs value="first" @tab-click="handleClick">
      <el-tab-pane label="工单详情" name="first">
        <el-card title="电梯信息" class="box-card">
          <div>电梯编号:{{model.elevator.name}}</div>
          <div>电梯类型:{{model.elevator.useTypeDesc}}</div>
          <div>电梯厂家:{{model.elevator.brandName}}</div>
          <div>电梯模块:{{model.elevator.modelName}}</div>
          <div>出厂时间:{{model.elevator.factoryDate}}</div>
          <div>电梯地址:{{model.elevator.realEstateName}}</div>
        </el-card>

        <el-card title="保养信息" class="box-card">
          <div>项目名称:{{model.maintainTask.maintenanceProjectName}}</div>
          <div>梯号/内部名称:{{model.maintainTask.elevatorName}}</div>
          <div>代理人姓名:{{model.maintainTask.assigneeName}}</div>
          <div>维保周期:{{model.maintainTask.cycleDesc}}</div>
          <div>计划日期:{{model.maintainTask.planDate}}</div>
          <div>实际开始时间:{{model.maintainTask.actualStartTime}}</div>
          <div>实际结束时间:{{model.maintainTask.actualEndTime}}</div>
          <div>实际工时:{{model.maintainTask.actualWorkHour}}</div>
        </el-card>
        <el-card title="保养信息" class="box-card">
          <div>创建人:{{model.workOrder.createBy}}</div>
          <div>创建时间:{{model.workOrder.createTime}}</div>
          <div>是否出发:{{model.workOrder.departed ? "是" : '否'}}</div>
          <div>出发时间:{{model.workOrder.departedTime ? workOrder.departedTime : ""}}</div>
          <div>是否到达:{{model.workOrder.arrived ? "是" : "否"}}</div>
          <div>到达时间:{{model.workOrder.arrivedTime ? workOrder.arrivedTime : ''}}</div>
          <div>保养状态:{{model.workOrder.maintained ? "完成" : "未完成"}}</div>
          <div>保养时间:{{model.workOrder.maintainedTime ? workOrder.maintainedTime : ""}}</div>
          <div>客户名称:{{model.workOrder.customerCompanyName}}</div>
          <div>客户确认:{{model.workOrder.customerConfirmed ? "是" : "否"}}</div>
          <div>客户确认类型:{{model.workOrder.customerConfirmTypeDesc}}</div>
          <div>客户确认时间:{{model.workOrder.customerConfirmedTime ? workOrder.customerConfirmedTime : ''}}</div>
          <div>是否回访:{{model.workOrder.visited ? "是" : '否'}}</div>
          <div>回访时间:{{model.workOrder.visitedTime ? workOrder.visitedTime : ''}}</div>
          <div>修改人员:{{model.workOrder.updateBy}}</div>
          <div>修改时间:{{model.workOrder.updateTime}}</div>
        </el-card>
      </el-tab-pane>
      <el-tab-pane label="保养内容" name="second">
        <el-table
          :data="model.maintainItems"
          label-position="left"
          border
          class="demo-table-expand">
          <el-table-column align="scope.row.center" type="index" width="50"></el-table-column>

          <el-table-column prop="elevatorCategoryDesc" label="电梯类别" align="center"></el-table-column>
          <el-table-column prop="content" label="保养内容" align="center"></el-table-column>
          <el-table-column prop="requirement" label="保养要求" align="center"></el-table-column>

          <el-table-column prop="typeDesc" label="保养周期" align="center">
            <template slot-scope="scope">
              {{scope.row.regulations instanceof Array ? scope.row.regulations.map(item=>{
                if (item.typeDesc){
                  return item.typeDesc.replace('[','')

                }else {
                  return ''
                }
              }):''}}
            </template>
          </el-table-column>
          <el-table-column prop="cycleDesc" label="保养类型" align="center">
            <template slot-scope="scope">
              {{scope.row.regulations instanceof Array ? scope.row.regulations.map(item=>{
                if (item.typeDesc){
                  return item.cycleDesc.replace('[','')

                }else {
                  return ''
                }
              }):''}}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          name: "",
          customerId: "",
          customerName: "",
          wbManagerId: "",
          wbManagerName: "",
          status: "",
          tasks:{},
          activities:[],
          elevator:{},
          maintainTask:{},
          workOrder:{},
          maintainItems:[],
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/maintain-order/${this.model.id}`).then(data => {
            this.contentLoading = false;
            console.log(data.maintainTask,80);
            this.model = data;
            if (!this.model.elevator) {
              this.model.elevator={};
            }
            if (!this.model.maintainItems) {
              this.model.maintainItems=[];
            }

            // const arr =[{content:data}];

          });
        }
      },
      selectCustomer(row) {
        this.model.customerId = row.id;
        this.model.customerName = row.name;
      },
      selectUserWb(row) {
        this.model.wbManagerId = row.id;
        this.model.wbManagerName = row.name;
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const parmas = {...this.model};
            this.$http
              .save("maintenance/maintain-plan", parmas)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.box-card{
  margin-bottom: 10px;
}
</style>