<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择电梯"
    class="small-padding"
    width="1600px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="maintenance/maintain-plan/page">
      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="handleSeniorClick">
        {{$l("common.search", "高级")}}
      </el-button>
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">{{$l("common.add", "新增")}}</el-button>
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">{{$l("common.add", "从合同中导入")}}</el-button>
      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="handleSeniorClick">
        {{$l("common.search", "高级")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('project.name','计划名称')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="队长姓名">
            <el-input v-model.trim="filter.captainName" clearable></el-input>
          </vm-search>
          <vm-search label="开始时间">
            <el-date-picker
              v-model="filter.planTimeFrom"
              style="width: 185px"
              type="date"
              value-format="yyyy-MM-dd 00:00:00">
            </el-date-picker>
          </vm-search>
          <vm-search label="结束时间">
            <el-date-picker
              v-model="filter.planTimeTo"
              style="width: 185px"
              type="date"
              value-format="yyyy-MM-dd 23:59:59">
            </el-date-picker>
          </vm-search>
          <vm-search label="项目状态">
            <vm-dict-select v-model="filter.planStatus" type="planStatus"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search v-if="senior" label="维保工姓名">
            <vm-dict-select v-model="filter.workerName" type="elevatorUsePlaceCategory"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="注册代码">
            <vm-dict-select v-model="filter.regCode" type="elevatorUseType"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="出厂编号">
            <vm-dict-select v-model="filter.factoryCode" type="elevatorUseStatus"></vm-dict-select>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="name" :label="$l('project.name','计划名称')"></el-table-column>
      <el-table-column prop="captainName" label="维保队长"></el-table-column>
      <el-table-column prop="projectQuantity" label="维保项目数量"></el-table-column>
      <el-table-column prop="elevatorQuantity" label="维保电梯台量">
      </el-table-column>
      <el-table-column prop="planStatusDesc" label="项目状态">
      </el-table-column>
      <el-table-column prop="planStartTime" label="计划开始时间" align="center"></el-table-column>
      <el-table-column prop="planEndTime" label="计划结束时间" align="center"></el-table-column>
      <el-table-column width="70" :label="$l('common.function','操作')" align="center">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
          index:0,
        },
        senior:false,
      };
    },
    methods: {
      open(index) {
        this.filter={};
        this.index = index;
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
