<template>
  <el-dialog
    v-el-drag-dialog
    append-to-body
    :visible.sync="dialogVisible"
    title="选择电梯"
    class="small-padding"
    width="1600px"
    :modal="false"
    top="3vh">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="elevator-archive/maintenance/page"
      @selection-change="handleSelectionChange">
      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="handleSeniorClick">
        {{$l("common.search", "高级")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <div class="vm-search">
            <vm-search label="楼盘名称">
              <el-input v-model.trim="filter.realEstateName" clearable></el-input>
            </vm-search>
            <vm-search label="楼宇名称">
              <el-input v-model.trim="filter.buildingName" clearable></el-input>
            </vm-search>
            <vm-search label="注册代码">
              <el-input v-model.trim="filter.regCode" clearable></el-input>
            </vm-search>
            <vm-search label="出厂编号">
              <el-input v-model.trim="filter.factoryCode" clearable></el-input>
            </vm-search>
            <vm-search label="电梯名称">
              <el-input v-model.trim="filter.name" clearable></el-input>
            </vm-search>
            <vm-search v-if="senior" label="电梯启用日期">
              <el-date-picker
                v-model="filter.factoryDate"
                type="date"
                value-format="yyyy-MM-dd hh:mm:ss"
                placeholder="选择日期">
              </el-date-picker>
            </vm-search>
            <vm-search v-if="senior" label="使用场所分类">
              <vm-dict-select v-model="filter.usePlaceCategory" type="elevatorUsePlaceCategory"></vm-dict-select>
            </vm-search>
            <vm-search v-if="senior" label="使用类型">
              <vm-dict-select v-model="filter.useType" type="elevatorUseType"></vm-dict-select>
            </vm-search>
            <vm-search v-if="senior" label="使用状态">
              <vm-dict-select v-model="filter.useStatus" type="elevatorUseStatus"></vm-dict-select>
            </vm-search>
            <vm-search v-if="senior" label="控制系统">
              <vm-dict-select v-model="filter.controlSystem" type="elevatorControlSystem"></vm-dict-select>
            </vm-search>
            <vm-search v-if="senior" label="是否有机房">
              <el-select v-model="filter.machineRoom">
                <el-option :value="true" label="是"></el-option>
                <el-option :value="false" label="否"></el-option>
              </el-select>
            </vm-search>
            <vm-search v-if="senior" label="电梯制造商">
              <el-input v-model.trim="filter.manufacturerName" clearable></el-input>
            </vm-search>
            <vm-search v-if="senior" label="电梯品牌">
              <el-input v-model.trim="filter.brandName" clearable></el-input>
            </vm-search>
            <vm-search v-if="senior" label="电梯型号">
              <el-input v-model.trim="filter.modelName" clearable></el-input>
            </vm-search>
          </div>
        </div>
      </template>
      <el-button
        v-if="checkbox"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="onSelectCheckbox">
        {{$l("common.add", "批量选择")}}
      </el-button>
      <el-table-column
        v-if="checkbox"
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column v-if="!checkbox" align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称" align="center" width="170"></el-table-column>
      <el-table-column prop="buildingName" label="楼宇名称" align="center" width="170"></el-table-column>
      <el-table-column prop="name" label="电梯名称" align="center" width="170"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center" width="170"></el-table-column>
      <el-table-column prop="factoryCode" label="出厂编号" align="center" width="170"></el-table-column>
      <el-table-column prop="manufacturerName" label="制造商" align="center"></el-table-column>
      <el-table-column prop="brandName" label="品牌" align="center"></el-table-column>
      <el-table-column prop="modelName" label="型号" align="center"></el-table-column>
      <el-table-column prop="controlSystemDesc" label="控制系统" align="center"></el-table-column>
      <el-table-column width="70" :label="$l('common.function','操作')" align="center">
        <template slot-scope="scope">
          <el-button type="primary" plain @click="onSelect(scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    props:{
      checkbox:{
        type: Boolean,
        default:true,
      },
    },
    data() {
      return {
        dialogVisible: false,
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
          index:0,
        },
        senior:false,
        selectList:[],
      };
    },
    methods: {
      open(index) {
        this.filter={};
        this.index = index;
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.$refs.vmTable.getList(1);
        });
      },
      onSelect(row) {
        this.dialogVisible = false;
        this.$emit("select", row,this.index);
      },
      onSelectCheckbox() {
        this.dialogVisible = false;
        this.$emit("select", this.selectList,this.index);
      },
      handleSelectionChange(val) {
        this.selectList = val;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
