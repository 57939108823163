<template>
  <div class="project-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="maintenance/maintain-order/page">
      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.missionOrderAdd.open(0)">{{$l("common.add", "创建临时保养工单")}}</el-button>


      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('project.name','工单编号')">
            <el-input v-model.trim="filter.orderCode" clearable></el-input>
          </vm-search>
          <vm-search label="客户公司名称">
            <el-input v-model.trim="filter.customerCompanyName" clearable></el-input>
          </vm-search>
          <vm-search label="维保项目名称">
            <el-input v-model.trim="filter.maintenanceProjectName" clearable></el-input>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="工单状态">
            <vm-dict-select v-model="filter.orderStatus" type="maintainOrderStatus"></vm-dict-select>
          </vm-search>

          <vm-search label="维保工姓名">
            <el-input v-model.trim="filter.assigneeName" clearable></el-input>
          </vm-search>
          <vm-search label="创建开始时间">
            <el-date-picker
              v-model="filter.createdTimeFrom"
              style="width: 185px"
              type="date"
              value-format="yyyy-MM-dd 00:00:00">
            </el-date-picker>
          </vm-search>
          <vm-search label="创建结束时间">
            <el-date-picker
              v-model="filter.createdTimeTo"
              style="width: 185px"
              type="date"
              value-format="yyyy-MM-dd 23:59:59">
            </el-date-picker>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="orderCode" :label="$l('project.name','工单编号')"></el-table-column>
      <el-table-column prop="customerCompanyName" label="客户公司名称">
      </el-table-column>
      <el-table-column prop="maintenanceProjectName" label="维保项目名称"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘名称"></el-table-column>
      <el-table-column prop="buildingName" label="楼盘楼宇"></el-table-column>
      <el-table-column prop="regCode" label="电梯注册代码"></el-table-column>
      <el-table-column prop="orderStatusDesc" label="工单状态"></el-table-column>
      <el-table-column prop="assigneeName" label="维保人员"></el-table-column>
      <el-table-column prop="serviceScore" label="服务评分"></el-table-column>
      <el-table-column prop="createTime" label="工单创建时间"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.detail", "工单详情")}}</el-button>
          <el-button type="primary" @click="$refs.maintainOrderLogs.open(scope.row.id)">{{$l("common.detail", "操作日志")}}</el-button>
          <a v-if="scope.row.signedDocument" :href="url+scope.row.signedDocument" download>{{$l("common.detail", "电子单据下载")}}</a>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <maintain-order-logs ref="maintainOrderLogs" @save-success="getList(-1)"></maintain-order-logs>
    <mission-order-add ref="missionOrderAdd" @save-success="getList(-1)"></mission-order-add>
  </div>
</template>
<script>
  import EditPage from "./OrderDetail.vue";
  import MaintainOrderLogs from "@/views/maintenance/maintenance/maintenanceOrder/MaintainOrderLogs";
  import MissionOrderAdd from "@/views/maintenance/maintenance/maintenanceOrder/MissionOrderAdd";
  import loginUtil from "@/util/loginUtil";

  export default {
    components: {EditPage,MaintainOrderLogs,MissionOrderAdd},
    data() {
      return {
        filter: {
          name: "",
          customerName: "",
          wbManagerName: "",
          status: "",
          companyId: loginUtil.getCompanyCode(),
        },
        url:"",
      };
    },
    mounted() {
      this.url = window.config.pictureUrl;
      console.log(window.config.pictureUrl);
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.projectName}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/maintenance/maintain-plan/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.projectName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
