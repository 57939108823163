<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('project.edit','日志信息'):$l('project.add','日志信息')"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-card title="电梯信息" class="box-card">
      <div v-for="(item,index) in model.logs" :key="index">
        {{item.operatorName}}已于{{item.operatedTime}}{{item.operateTypeDesc}}
      </div>
    </el-card>

    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
  // import moment from "moment";
  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          name: "",
          customerId: "",
          customerName: "",
          wbManagerId: "",
          wbManagerName: "",
          status: "",
          tasks:{},
          activities:[],
          elevator:{},
          maintainTask:{},
          workOrder:{},
          logs:[],
        },
      };
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/maintain-order/${this.model.id}`).then(data => {
            this.contentLoading = false;
            console.log(data.maintainTask,80);
            this.model = data;
            if (!this.model.elevator) {
              this.model.elevator={};
            }
            // const arr =[{content:data}];

          });
        }
      },
      selectCustomer(row) {
        this.model.customerId = row.id;
        this.model.customerName = row.name;
      },
      selectUserWb(row) {
        this.model.wbManagerId = row.id;
        this.model.wbManagerName = row.name;
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            const parmas = {...this.model};
            this.$http
              .save("maintenance/maintain-plan", parmas)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.box-card{
  margin-bottom: 10px;
}
</style>