<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('project.edit','编辑临时工单'):$l('project.add','新增临时工单')"
    width="1200px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('project.labelWidth','80px')">
      <h3>电梯</h3>
      <el-divider></el-divider>
      <div class="vm-separate">
        <el-form-item :label="$l('project.name','电梯')" prop="elevatorName">
          <vm-select-input
            :maxlength="0"
            :value="model.workOrder.elevatorName"
            @select="$refs.elevatorSelect.open()"></vm-select-input>
        </el-form-item>
        <div class="vm-separate">
          <el-form-item :label="$l('project.assigneeName','维保项目')" prop="maintenanceProjectId">
            <el-input v-model="model.workOrder.maintenanceProjectName" disabled :placeholder="$l('common.enter','请输入')"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('project.name','维保类型')" prop="typeDesc">
          <vm-dict-select v-model="model.workOrder.typeDesc" disabled type="wbType"></vm-dict-select>
        </el-form-item>
        <el-form-item label="项目负责人" prop="assigneeId">
          <el-input v-model="model.workOrder.assigneeName" disabled :placeholder="$l('common.enter','请输入')"></el-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item label="注册代码" prop="regCode">
          <el-input v-model="model.workOrder.regCode" disabled :placeholder="$l('common.enter','请输入')"></el-input>
        </el-form-item>
        <div></div>
      </div>
      <h3>任务</h3>
      <el-divider></el-divider>
      <div class="vm-separate">
        <el-form-item label="维保周期" :prop="cycle">
          <vm-dict-select v-model="model.maintainTask.cycle" type="maintainCycle"></vm-dict-select>
        </el-form-item>
        <el-form-item :label="$l('project.assigneeName','计划负责人')" prop="assigneeId">
          <vm-select-input :value="model.maintainTask.assigneeName" @select="$refs.userSelectWb.open(index)"></vm-select-input>
        </el-form-item>
      </div>
      <div class="vm-separate">
        <el-form-item :label="$l('project.name','计划日期')" prop="planDate">
          <el-date-picker
            v-model="model.maintainTask.planDate"
            type="datetime"
            style="width: 100%"
            placeholder="选择日期"
            value-format="yyyy-MM-dd hh:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$l('device.no','计划工时')" prop="planWorkHour">
          <el-input v-model="model.maintainTask.planWorkHour" :placeholder="$l('common.enter','请输入')"></el-input>
        </el-form-item>
      </div>
    </el-form>

    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>

      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
      <!--      <el-button v-if="model.id" type="primary" @click="toTask">转任务</el-button>-->
    </span>
    <plan-select ref="planSelect" @select="planSelect"></plan-select>

    <user-select ref="userSelectWb" @select="selectUserWb"></user-select>
    <user-select ref="userSelectMission" @select="selectUserMission"></user-select>
    <customer-select ref="customerSelect" @select="selectCustomer($event)"></customer-select>
    <elevator-select-checkbox ref="elevatorSelect" :checkbox="false" @select="elevatorSelect"></elevator-select-checkbox>
    <project-select ref="projectSelect" @select="projectSelect"></project-select>
  </el-dialog>
</template>
<script>
  import UserSelect from "@/views/user/UserSelect";
  import CustomerSelect from "@/views/customer/CustomerSelect";
  import ProjectSelect from "@/views/project/ProjectSelect";
  import PlanSelect from "@/views/maintenance/PlanSelect";
  import ElevatorSelectCheckbox from "@/views/elevator/ElevatorSelectCheckbox";
  export default {
    components: {UserSelect, CustomerSelect,ProjectSelect,PlanSelect,ElevatorSelectCheckbox},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          name: "",
          customerId: "",
          customerName: "",
          wbManagerId: "",
          wbManagerName: "",
          tasks:[],
          maintainTask:{},
          workOrder:{},
        },

      };
    },
    watch:{
      tableData:{
        handler(params) {
          console.log(159,params);
          this.$set(this.model,"fault",params);
        },
        deep:true,
        immediate:true,
      },
    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`maintenance/maintain-task/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
          });
        }
      },
      selectCustomer(row) {

        this.model.customerId = row.id;
        this.model.customerName = row.name;
      },
      projectSelect(row) {
        this.$set(this.model,"maintenanceProjectName",row.name);
        this.$set(this.model,"maintenanceProjectId",row.id);

      },
      planSelect(row) {
        this.$set(this.model,"planName",row.name);
        this.$set(this.model,"planId",row.id);
        this.$set(this.model,"planStartTime",row.planStartTime);
        this.$set(this.model,"planEndTime",row.planEndTime);
        this.missionDisable=true;
      },
      elevatorSelect(row) {

        this.$set(this.model.workOrder,"elevatorId",row.id);
        this.$set(this.model.workOrder,"elevatorName",row.name);
        this.$set(this.model.workOrder,"assigneeName",row.maintenanceProject.maintainAssigneeName);
        this.$set(this.model.workOrder,"assigneeId",row.maintenanceProject.maintainAssigneeId);
        this.$set(this.model.workOrder,"maintenanceProjectId",row.maintenanceProject.id);
        this.$set(this.model.workOrder,"maintenanceProjectName",row.maintenanceProject.name);
        this.$set(this.model.workOrder,"type",row.maintenanceProject.type);
        this.$set(this.model.workOrder,"typeDesc",row.maintenanceProject.typeDesc);
        this.$set(this.model.workOrder,"regCode",row.regCode);

      },
      selectUserWb(row) {
        this.$set(this.model.maintainTask,"assigneeId",row.id);
        this.$set(this.model.maintainTask,"assigneeName",row.name);
      },
      selectUserMission(row,index,idx) {
        console.log(index,idx,2000);
        let num;
        let count;
        this.model.tableData.forEach((item,i)=>{
          if (item.id=== index) {
            num=i;
          }
        });
        this.model.tableData[num].tasks.forEach((item,i)=>{
          if (item.id=== idx) {
            count=i;
          }
        });

        this.$set(this.model.tableData[num].tasks[count],"assigneeId",row.id);
        this.$set(this.model.tableData[num].tasks[count],"assigneeName",row.name);


      },

      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;


            const parmas = this.model;

            this.$http
              .save("maintenance/maintain-order", parmas)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      addLine() {
        this.model.tasks.push({
          key: this.$util.genKey(),
          "id": 0,
          "tenantId": 0,
          "planId": 0,
          "projectId": 0,
          "elevatorId": 0,
          "cycle": "",
          "planDate": "",
          "planWorkHour": 0,
          "assigned": true,
          "assigneeId": 0,
          "assignedTime": "",
          "maintainOrderId": 0,
          "actualStartTime": "",
          "actualEndTime": "",
          "actualWorkHour": 0,
          "projectName": "",
          "elevatorCode": "",
          "assigneeName": "",
        });
      },
      deleteLine() {
        this.model.tasks.pop();
      },
      onchange(e) {
        this.$set(this.model,"planName",e);
      },
      onAdd(row) {
        if (row.tasks instanceof Array && row.tasks.length>0) {
          row.tasks.push({id:Math.random()});
        }else {
          row.tasks=[{cycle: "",planDate:"",id:Math.random()}];
        }
        this.tableData = this.tableData.map((item)=>{
          if (item.id===row.id) {
            item=row;
            return item;
          }
          return item;
        });
        this.model.tableData=this.tableData;
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>